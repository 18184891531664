
<script setup>

import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import {
  registerApi
} from './fetch'

const formEl = ref(null)
const form = ref({
  username: '',
  password: '',
  nickname: ''
})

const router = useRouter()

const onRegister = async () => {
  try {
    await formEl.value.validate()
    await registerApi({
      username: form.value.username,
      password: form.value.password,
      nickname: form.value.nickname
    })
    ElMessage.success('注册成功！')
    router.push({ path: '/login' })
  } catch (e) {
    ElMessage.error(e.msg || e)
  }
}
</script>

<template>
  <div class="login_wrap">
    <el-form ref="formEl" class="form" label-width="80px">
      <el-form-item label="用户名">
        <el-input v-model="form.username"/>
      </el-form-item>
      <el-form-item label="密码">
        <el-input type="password" v-model="form.password"/>
      </el-form-item>
      <el-form-item label="昵称">
        <el-input v-model="form.nickname"/>
      </el-form-item>
      <el-form-item>
        <el-button plain @click="onRegister">注册</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<style lang="stylus" scoped>
.login_wrap
  padding 20px
  .form
    width 460px
    margin 0 auto
</style>
